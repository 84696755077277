import GunbotStorage, { WALLET_ADDRESS } from 'assets/js/gunbot.storage';
import axios from 'axios';
import { getServerUrl } from 'assets/js/gunbot.helper';

const serverUrl = getServerUrl();

export default function (baseURL) {
  const Axios = axios.create({
    responseType: 'json',
    baseURL: baseURL ? `${serverUrl}/api/v1/${baseURL}` : `${serverUrl}/`,
  });
  Axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');
    config.headers.walletAddress = GunbotStorage.get(WALLET_ADDRESS, '');
    config.headers.xmg8 = true;

    return config;
  });
  return Axios;
}
