import axios from './axios';

const binanceHost = '/api/v1/tradingView';
const Axios = axios();

function buildUrl(base, params) {
    const url = new URL(base, window.location.origin); // Use window.location.origin for relative URLs
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

    return url.pathname + '?' + params.toString(); // Return the relative URL path and search parameters
}

export const serverTime = function () {
  return Axios.get(binanceHost + '/serverTime').then(res => res.data.serverTime);
};

export const symbols = function () {
  return Axios.get(binanceHost + '/exchangeInfo').then(res => res.data.symbols);
};

export const klines = function (exchange, pair, interval, startTime, endTime, limit) {
    const params = new URLSearchParams({
        exchange: encodeURIComponent(exchange),
        pair,
        interval,
        limit,
        startTime,
        endTime
    });
    const url = buildUrl('/klines', params);
    return Axios.get(binanceHost + url).then(res => res.data);
};

export const marks = function (exchange, pair, interval, startTime, endTime) {
    const params = new URLSearchParams({
        exchange: encodeURIComponent(exchange),
        pair,
        interval,
        startTime,
        endTime
    });
    const url = buildUrl('/marks', params);
    return Axios.get(binanceHost + url).then(res => {
        let withLocalTimestamps = [];
        res.data.forEach(element => {
            let text = [];
            element.tooltip.forEach(item => {
                if (item.split(' ').length === 3 && item.endsWith('convert')) {
                    text.push(`${item.split(' ')[0]} ${new Date(parseFloat(item.split(' ')[1])).toLocaleTimeString()}`);
                } else {
                    text.push(item);
                }
            });
            element.tooltip = text;
            withLocalTimestamps.push(element);
        });
        return withLocalTimestamps;
    });
};
