import React, { useContext, useEffect, useRef, useState } from 'react';
import SocketIOClient from 'socket.io-client';
import PropTypes from 'prop-types';
import { getServerUrl } from 'assets/js/gunbot.helper';

const serverUrl = getServerUrl();
export const SocketContext = React.createContext({});

const io = SocketIOClient(serverUrl, Object.assign({}, { autoConnect: false }));
export function CreateSocket(props) {
  const socket = useRef(io);
  const [initializing, setInit] = useState(true);

  useEffect(() => {
    socket.current.open();
    setInit(false);

    return () => {
      socket.current.close();
    };
  }, []);

  if (initializing) {
    return <></>;
  }
  return <SocketContext.Provider value={socket.current}>{props.children}</SocketContext.Provider>;
}

CreateSocket.propTypes = {
  url: PropTypes.string,
  options: PropTypes.object,
};

export class Event extends React.Component {
  eventCallback = (...args) => {
    //console.log('SOCKET EVNET = ' + (new Date()).toLocaleTimeString(), this.props.event);
    this.props.handler(...args);
  };

  componentDidMount() {
    this.context.on(this.props.event, this.eventCallback);
  }

  componentWillUnmount() {
    this.context.off(this.props.event, this.eventCallback);
  }

  render() {
    return this.props.children || false;
  }
}

Event.contextType = SocketContext;

Event.propTypes = {
  event: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
};

export const SocketEvent = ({ event, handler, children }) => {
  const socket = useContext(SocketContext);
  useEffect(() => {
    socket.on(event, handler);
    return () => {
      socket.off(event, handler);
    };
  }, [socket, event, handler]);

  return children || <></>;
};

export const useSocket = () => useContext(SocketContext);
